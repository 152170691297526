<template>
  <div class="col-12 p-0 align-self-end above">
    <div class="row m-0 background-green py-3 justify-content-center">
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  name: 'Recurring Payment Footer'
}

</script>
